.drift-play {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.drift-play-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.return-button {
  position: fixed;
  top: 10px;
  right: 10px;
  padding: 8px 16px;
  background-color: var(--primary-color);
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.return-button:hover {
  background-color: var(--primary-color-hover);
} 