.home-page-vehicle-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 20px;
  row-gap: 20px;
}

.home-page-vehicle-card {
  width: 150px;
}

.home-page-header-image {
  width: 100%;
  max-width: 1024px;
  object-fit: cover;
}

.home-page-description-panel {
  display: grid;
  grid-template-columns: 1fr 1fr; 
  padding: 10px 20px 20px 20px; 
  max-width: 900px; 
  margin: auto; 
  column-gap: 20px;
  row-gap: 20px;
}

.home-page-panel-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; 
  justify-content: center; 
  row-gap: 20px; 
  column-gap: 20px;
  padding: 10px; 
}

@media (max-width: 750px) {
  .home-page-description-panel {
    grid-template-columns: unset; 
    grid-template-rows: min-content min-content; 
  }
}

@media (max-width: 500px) {
  .home-page-description-panel {
    padding: 0px 10px 10px 10px; 
    row-gap: 10px;
  }
  .home-page-panel-grid {
    row-gap: 15px; 

  }
}

/* Heading 1 */
h1 {
  font-size: 2.5em; /* Adjust size as needed */
  font-weight: bold;
  line-height: 1.2;
  margin: 0.67em 0; /* Top and bottom margin */
  color: #222; /* Adjust color as needed */
  text-align: center;}

/* Heading 2 */
h2 {
  font-size: 2em; /* Adjust size as needed */
  font-weight: bold;
  line-height: 1.3;
  margin: 0.75em 0; /* Top and bottom margin */
  color: #333; /* Adjust color as needed */
}

/* Heading 3 */
h3 {
  font-size: 1.75em; /* Adjust size as needed */
  font-weight: bold;
  line-height: 1.4;
  margin: 0.83em 0; /* Top and bottom margin */
  color: #444; /* Adjust color as needed */
}

/* Heading 4 */
h4 {
  font-size: 1.5em; /* Adjust size as needed */
  font-weight: bold;
  line-height: 1.5;
  margin: 1.12em 0; /* Top and bottom margin */
  color: #555; /* Adjust color as needed */
}

/* Heading 5 */
h5 {
  font-size: 1.25em; /* Adjust size as needed */
  font-weight: bold;
  line-height: 1.6;
  margin: 1.5em 0; /* Top and bottom margin */
  color: #666; /* Adjust color as needed */
}

/* Heading 6 */
h6 {
  font-size: 1em; /* Adjust size as needed */
  font-weight: bold;
  line-height: 1.6;
  margin: 1.67em 0; /* Top and bottom margin */
  color: #777; /* Adjust color as needed */
}

.character-card {
  transition: transform 0.3s ease-in-out;
}

.character-card:hover {
  transform: scale(1.05);
}

.footer-link {
  color: #888;
  text-decoration: none;
  font-size: 0.9em;
}

.footer-link:hover {
  color: #fff;
  text-decoration: underline;
}