/* New clean implementation */
.new-host-page-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: calc(100vh - 100px); /* Account for header/footer */
  padding: 8px;
}

/* Main row for video, buttons, chat */
.new-host-main-row {
  display: flex;
  gap: 8px;
  justify-content: center;
  position: relative;
  height: 0;
  padding-top: 33.75%;
}

/* Left column - Buttons */
.new-host-buttons {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 160px;
  display: grid;
  grid-template-columns: 80px 80px;
  gap: 4px;
  background: var(--panel-color);
  padding: 4px;
  border-radius: 6px;
  overflow: hidden;
}

/* Center column - Video */
.new-host-video {
  position: absolute;
  width: calc(100% - 520px); /* Account for buttons and chat width */
  height: 100%;
  top: 0;
  left: 170px; /* Account for buttons width + gap */
  background: var(--panel-color);
  border-radius: 6px;
  overflow: hidden;
}

.new-host-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* Right column - Twitch chat */
.new-host-twitch-chat {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 350px;
  background: var(--panel-color);
  border-radius: 6px;
  overflow: hidden;
}

.new-host-twitch-chat iframe {
  width: 100%;
  height: 100%;
  border: none;
}

/* Extracted Timeline and Command Styles */

/* Command timeline */
.command-timeline {
  height: 70px;
  background: var(--panel-color);
  border-radius: 6px;
  padding: 8px;
  flex-shrink: 0;
  border: 1px solid #333;
}

.timeline-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
  font-size: 0.9em;
  color: #888;
  height: 16px;
  padding: 0 4px;
}

.timeline-header .emoji {
  font-size: 1.2em;
}

.timeline-header .action-bolts {
  margin-left: auto; /* Push to right side */
  font-size: 0.8em;
  color: #ff9900; /* Match the timer color */
  letter-spacing: 2px; /* Space out the bolts */
}

.timeline-track {
  height: 40px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 4px;
  display: flex;
  gap: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Command items */
.command-item {
  height: 32px;
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #1a1a1a, #2a2a2a);
  border: 1px solid #333;
  border-radius: 4px;
  padding: 4px;
  position: relative;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.command-item .emoji {
  font-size: 1.2em;
}

.command-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.command-text {
  font-size: 0.7em;
  white-space: nowrap;
  text-transform: uppercase;
  display: none;
}

.command-timer {
  font-size: 0.8em;
  color: #ff9900;
  position: absolute;
  top: 2px;
  right: 2px;
  font-weight: bold;
}

.command-item .progress {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  background: linear-gradient(90deg, #ffd700, #ff6b6b);
  width: 100%;
  transform-origin: left;
  animation: countdown 3s linear forwards;
}

@keyframes countdown {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

.command-item.executing {
  animation: flash 0.5s ease;
}

.command-item.removing {
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.3s ease;
}

.command-heard {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.85);
  border-radius: 4px;
  font-size: 1.2em;
  gap: 2px;
  z-index: 10;
  pointer-events: none;
}

.command-heard .icon,
.command-heard .tick {
  opacity: 0;
  animation: popIn 0.3s ease forwards;
}

@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .new-host-page-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .new-host-main-row {
    height: auto;
    padding-top: 0;
    flex-direction: column;
    position: static;
    gap: 4px;
  }

  .new-host-video {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    left: 0;
  }

  .new-host-buttons {
    position: static;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 4px;
    gap: 4px;
  }

  .new-host-twitch-chat,
  .host-page-chatbox {
    display: none;
  }

  /* Grid layout for button groups */
  .new-host-game-actions,
  .new-host-special-actions,
  .new-host-menu-controls {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Fixed 4 columns instead of auto-fit */
    gap: 4px;
  }

  .new-host-button {
    width: 100%;
    height: 40px;
    font-size: 0.7em;
    padding: 2px;
  }

  .new-host-timeline-row {
    margin-top: 4px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


/* Chat box */
.host-page-chatbox {
  height: 150px;
  background: var(--panel-color);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
}

.chat-input {
  display: flex;
  padding: 8px;
  gap: 8px;
  border-top: 1px solid var(--border-color);
}

.chat-input input {
  flex: 1;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
}

.chat-input button {
  padding: 4px 12px;
  border-radius: 4px;
  background: var(--primary-color);
  border: none;
  color: white;
}

/* Dialog styles */
.dialog-cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.dialog-panel-centered {
  background: var(--background-color);
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

/* Mobile layout */
@media (max-width: 768px) {
  .new-host-page-container {
    gap: 4px;
  }

  .new-host-main-row {
    height: auto;
    padding-top: 0;
    flex-direction: column;
    position: static;
    gap: 4px;
  }

  /* Video */
  .new-host-video {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    left: 0;
  }

  /* Timeline */
  .command-timeline {
    height: 60px;
    margin: 0;
  }

  /* Buttons */
  .new-host-buttons {
    position: static;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 4px;
    gap: 4px;
  }

  .new-host-game-actions {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Fixed 4 columns instead of auto-fit */
    gap: 4px;
  }

  .new-host-special-actions {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Fixed 4 columns instead of auto-fit */
    gap: 4px;
  }

  .new-host-menu-controls {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Fixed 4 columns instead of auto-fit */
    gap: 4px;
  }

  /* Chat */
  .new-host-twitch-chat {
    position: static;
    width: 100%;
    height: 300px;
  }

  /* Set the order of elements */
  .new-host-video { order: 1; }
  .command-timeline { order: 2; }
  .new-host-buttons { order: 3; }
  .host-page-chatbox { order: 4; }
  .new-host-twitch-chat { order: 5; }
}

/* Small screens (iPhone SE etc) */
@media (max-width: 380px) {
  .new-host-buttons {
    padding: 2px;
    gap: 2px;
  }

  .new-host-game-actions,
  .new-host-special-actions,
  .new-host-menu-controls {
    gap: 2px;
    grid-template-columns: repeat(2, 1fr); /* 2 columns for very small screens */
  }

  .new-host-button {
    min-width: 50px;
    height: 36px;
    font-size: 0.65em;
    padding: 1px;
  }

  .command-timeline {
    height: 60px;
  }

  .host-page-chatbox {
    height: 120px;
  }
}

.host-page-car-cosmetics {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  /* background-color: orange; */
  /* margin-top: 0px; */
  /* margin-bottom: 30px; */
  row-gap: 0px;
  column-gap: 5px;
  row-gap: 5px;
  height: 130px;
  /* width: 190px; */
  /* background-color: orange; */
}

.host-page-car-cosmetic {
  width: calc(20% - 4px);
  aspect-ratio: 1;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 4px;
  font-size: 0.8em;
  text-align: center;
}

.host-page-cosmetic-select {
  width: 120px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 8px;
  gap: 4px;
}

.host-page-cosmetic-select img {
  width: 100%;
  height: 80px;
  object-fit: contain;
}

.host-page-cosmetic-select.selected {
  background-color: rgba(255, 255, 255, 0.1);
  outline: 2px solid orange;
}

/* Car/Driver selection panels */
.host-page-car-select,
.host-page-driver-select {
  width: 120px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 8px;
  gap: 4px;
}

.host-page-car-select img,
.host-page-driver-select img {
  width: 100%;
  height: 80px;
  object-fit: contain;
}

.host-page-car-select.selected,
.host-page-driver-select.selected {
  background-color: rgba(255, 255, 255, 0.1);
  outline: 2px solid orange;
}

.host-page-celebration-button {
  border: 1px solid #ffffff40;
  cursor: pointer;
  border-radius: 6px;
  text-align: center;
  padding: 2px;
  margin-top: 5px;
}

/* Styles for the driver customize dialog */
.customize-driver-dialog {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.customize-driver-dialog > div:nth-child(2) {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 5px;
}

.customize-driver-dialog .driver-stats {
  overflow: visible;
}

/* Styles for the car customize dialog */
.#-dialog {
  overflow-y: auto;
}

.customize-car-dialog .host-page-modal-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  gap: 10px;
  padding: 10px;
  overflow: visible;
}

/* iPhone SE and similar small screens */
@media (max-width: 380px) {
  .host-page-buttons {
    padding: 3px;
  }

  .host-page-button-grid {
    gap: 3px;
  }

  .host-page-button {
    padding: 4px 0;
    font-size: 0.8em;
  }

  .host-page-chatbox {
    height: 150px;
  }

  .host-page-chat {
    width: 100%;
    height: 400px;
    min-height: 300px;
  }

  .host-page-chat iframe {
    width: 100%;
    height: 100%;
    display: block; /* Ensure iframe is displayed */
  }
}

@media (max-width: 540px) {
  .host-page-modal-content {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (max-width: 420px) {
  .host-page-modal-content {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/* Add these styles at the end of your existing CSS file */

.customize-driver-dialog {
  max-width: 400px;
  width: 90vw;
  max-height: 90vh;
  height: auto; /* Change from fixed height to auto */
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.customize-driver-content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: calc(90vh - 100px); /* Adjust based on header and footer height */
}

.driver-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 auto; /* Prevent flex stretching */
}

.driver-stats {
  margin-top: auto; /* Push to bottom of its container */
  width: 100%; /* Ensure full width */
}

.customize-driver-footer {
  margin-top: auto; /* Push to bottom of dialog */
  padding: 10px;
}

@media (max-height: 600px), (orientation: landscape) {
  .customize-driver-dialog {
    max-height: 80vh;
  }
}

.dialog-panel-centered {
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  height: auto;
}

.dialog-scrollable-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  margin-bottom: 10px; /* Add some space above the footer */
}

.dialog-footer {
  flex-shrink: 0; /* Add this line */
  padding: 10px;
  border-top: 1px solid var(--border-color);
}

@media (max-height: 600px) {
  .dialog-panel-centered {
    max-height: 80vh;
    height: 80vh;
  }
}

/* Styles for the driver customize dialog */
.customize-driver-dialog {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.customize-driver-dialog > div:nth-child(2) {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 5px;
}

.customize-driver-dialog .driver-stats {
  overflow: visible;
}

/* Styles for the car customize dialog */
.customize-car-dialog {
  overflow-y: auto;
}

.customize-car-dialog .host-page-modal-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  gap: 10px;
  padding: 10px;
  overflow: visible;
}

/* Media query for both dialogs */
@media (max-height: 600px), (orientation: landscape) {
  .dialog-panel-centered {
    max-height: 80vh;
  }
}

/* Ensure consistent image sizes in car dialog */
.customize-car-dialog .host-page-car-cosmetic img {
  max-width: 100%;
  height: auto;
}

.select-car-dialog .host-page-modal-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
}

.driver-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: auto;
}

.driver-stats {
  margin-top: auto; /* This will push the stats to the bottom without stretching */
}

.host-page-car-cosmetics {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  /* background-color: orange; */
  /* margin-top: 0px; */
  /* margin-bottom: 30px; */
  row-gap: 0px;
  column-gap: 5px;
  row-gap: 5px;
  height: 130px;
  /* width: 190px; */
  /* background-color: orange; */
}

.host-page-car-cosmetic {
  width: 57px;
  height: 57px;
  border: 1px solid #ffffff40;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
  color: gray;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.host-page-celebration-button {
  border: 1px solid #ffffff40;
  cursor: pointer;
  border-radius: 6px;
  text-align: center;
  padding: 2px;
  margin-top: 5px;
}

.host-page-modal-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
  padding: 10px;
  background-color: var(--background-color);
  margin: 10px;
  overflow: auto;
}

.host-page-modal-content::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.host-page-modal-content::-webkit-scrollbar-track {
  width: 10px;
  border-radius: 5px;
  background: var(--scroll-track-color);
}

.host-page-modal-content::-webkit-scrollbar-thumb {
  width: 10px;
  border-radius: 5px;
  background: var(--scroll-thumb-color);
}

/* Dialog and Modal styles - add or update these */
.dialog-cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.dialog-panel-centered {
  background: var(--background-color);
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

/* Car cosmetics grid */
.host-page-modal-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  padding: 10px;
  background-color: var(--background-color);
  margin: 10px;
  overflow: auto;
  max-height: calc(90vh - 140px); /* Leave room for header/footer */
}

/* Dialog header */
.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid var(--border-color);
}

/* Dialog content */
.dialog-content {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

/* Dialog footer */
.dialog-footer {
  padding: 10px;
  border-top: 1px solid var(--border-color);
  display: flex;
  justify-content: center;
}

/* Customize car/driver specific styles */
.customize-car-dialog,
.customize-driver-dialog {
  width: 90%;
  max-width: 400px;
  background: var(--background-color);
  border-radius: 8px;
  overflow: hidden;
}

/* Scrollbar styles */
.host-page-modal-content::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.host-page-modal-content::-webkit-scrollbar-track {
  background: var(--scroll-track-color);
  border-radius: 5px;
}

.host-page-modal-content::-webkit-scrollbar-thumb {
  background: var(--scroll-thumb-color);
  border-radius: 5px;
}

/* Game actions layout */
.new-host-game-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

/* Special actions container */
.new-host-special-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 4px;
}

/* Menu controls */
.new-host-menu-controls {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

/* Button styles */
.new-host-button {
  width: 100%;
  height: 40px;
  font-size: 0.75em;
  padding: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #1a1a1a;
  border: 1px solid #333;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  position: relative;
  text-align: center;
  line-height: 1.2; /* Add consistent line height */
}

/* Corner emoji positioning */
.new-host-button .corner-emoji {
  position: absolute;
  top: 2px;
  right: 2px;
  font-size: 8px;
  opacity: 0.8;
}

/* Main emoji and text layout */
.new-host-button br {
  margin: 2px 0; /* Add space between emoji and text */
}

/* Menu buttons (no line breaks) */
.new-host-menu-controls .new-host-button {
  flex-direction: row;
  gap: 4px;
}

.new-host-menu-controls .new-host-button br {
  display: none;
}

/* Primary button (Join Race) */
.new-host-button.primary {
  background: linear-gradient(45deg, #ff6b6b, #ff8e53);
  border: none;
  font-weight: bold;
}

.new-host-button.primary:hover {
  background: linear-gradient(45deg, #ff8e53, #ff6b6b);
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .new-host-game-actions {
    flex-direction: row;
    align-items: center;
  }

  .new-host-special-actions {
    flex-direction: row;
    margin: 0;
  }

  .new-host-menu-controls {
    flex-direction: row;
    margin: 0;
  }

  .new-host-menu-controls .new-host-button {
    width: auto;
    padding: 4px 8px;
  }
}

/* Status indicators */
.host-page-network {
  position: absolute;
  bottom: 8px;
  left: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.host-page-chat-button {
  position: absolute;
  bottom: 8px;
  right: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 2px;
}

.new-host-timeline-row {
  width: 100%;
  margin-top: 8px;
}

@media (max-width: 768px) {
  .new-host-main-row {
    height: auto;
    padding-top: 0;
    flex-direction: column;
    position: static;
    gap: 4px;
  }

  .new-host-video {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    left: 0;
  }

  .new-host-twitch-chat {
    display: none; /* Hide twitch chat on mobile */
  }

  .new-host-timeline-row {
    margin-top: 4px;
  }

  .new-host-buttons {
    position: static;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 4px;
    gap: 4px;
  }
}

/* Action buttons (game actions) */
.new-host-game-actions .new-host-button {
  background: linear-gradient(45deg, #ff8e53, #ff6b6b);
  border: none;
}

.new-host-game-actions .new-host-button:hover {
  background: linear-gradient(45deg, #ff6b6b, #ff8e53);
}

/* Special action buttons */
.new-host-special-actions .new-host-button {
  background: linear-gradient(45deg, #ff9966, #ff7043);
  border: none;
}

.new-host-special-actions .new-host-button:hover {
  background: linear-gradient(45deg, #ff7043, #ff9966);
}

/* Menu buttons */
.new-host-menu-controls .new-host-button {
  background: linear-gradient(45deg, #4a90e2, #357abd);
  border: none;
}

.new-host-menu-controls .new-host-button:hover {
  background: linear-gradient(45deg, #357abd, #4a90e2);
}

/* Primary button (Join Race) stays special */
.new-host-button.primary {
  background: linear-gradient(45deg, #2ecc71, #27ae60);
  border: none;
  font-weight: bold;
}

.new-host-button.primary:hover {
  background: linear-gradient(45deg, #27ae60, #2ecc71);
}

.host-page-modal-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
  padding: 10px;
  background-color: var(--background-color);
  margin: 10px;
  overflow: auto;
}

.host-page-modal-content::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.host-page-modal-content::-webkit-scrollbar-track {
  width: 10px;
  border-radius: 5px;
  background: var(--scroll-track-color);
}

.host-page-modal-content::-webkit-scrollbar-thumb {
  width: 10px;
  border-radius: 5px;
  background: var(--scroll-thumb-color);
}

.host-page-car-cosmetics {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  /* background-color: orange; */
  /* margin-top: 0px; */
  /* margin-bottom: 30px; */
  row-gap: 0px;
  column-gap: 5px;
  row-gap: 5px;
  height: 130px;
  /* width: 190px; */
  /* background-color: orange; */
}

.host-page-car-cosmetic {
  width: 57px;
  height: 57px;
  border: 1px solid #ffffff40;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
  color: gray;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.host-page-celebration-button {
  border: 1px solid #ffffff40;
  cursor: pointer;
  border-radius: 6px;
  text-align: center;
  padding: 2px;
  margin-top: 5px;
}

@media (max-width: 540px) {
  .host-page-modal-content {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (max-width: 420px) {
  .host-page-modal-content {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/* Add these styles at the end of your existing CSS file */

.customize-driver-dialog {
  max-width: 400px;
  width: 90vw;
  max-height: 90vh;
  height: auto; /* Change from fixed height to auto */
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.customize-driver-content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: calc(90vh - 100px); /* Adjust based on header and footer height */
}

.driver-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 auto; /* Prevent flex stretching */
}

.driver-stats {
  margin-top: auto; /* Push to bottom of its container */
  width: 100%; /* Ensure full width */
}

.customize-driver-footer {
  margin-top: auto; /* Push to bottom of dialog */
  padding: 10px;
}

@media (max-height: 600px), (orientation: landscape) {
  .customize-driver-dialog {
    max-height: 80vh;
  }
}

.dialog-panel-centered {
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  height: auto;
}

.dialog-scrollable-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  margin-bottom: 10px; /* Add some space above the footer */
}

.dialog-footer {
  flex-shrink: 0; /* Add this line */
  padding: 10px;
  border-top: 1px solid var(--border-color);
}

@media (max-height: 600px) {
  .dialog-panel-centered {
    max-height: 80vh;
    height: 80vh;
  }
}

/* Styles for the driver customize dialog */
.customize-driver-dialog {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.customize-driver-dialog > div:nth-child(2) {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 5px;
}

.customize-driver-dialog .driver-stats {
  overflow: visible;
}

/* Styles for the car customize dialog */
.customize-car-dialog {
  overflow-y: auto;
}

.customize-car-dialog .host-page-modal-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  gap: 10px;
  padding: 10px;
  overflow: visible;
}

/* Media query for both dialogs */
@media (max-height: 600px), (orientation: landscape) {
  .dialog-panel-centered {
    max-height: 80vh;
  }
}

/* Ensure consistent image sizes in car dialog */
.customize-car-dialog .host-page-car-cosmetic img {
  max-width: 100%;
  height: auto;
}

.select-car-dialog .host-page-modal-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
}

.driver-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: auto;
}

.driver-stats {
  margin-top: auto; /* This will push the stats to the bottom without stretching */
}
