.dialog-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialog-panel-centered {
  background-color: var(--panel-color);
  border-radius: 5px;
  max-width: 400px;
  width: 90%;
  max-height: 90vh;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.dialog-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
}

.close-button {
  cursor: pointer;
  color: gray;
}

.save-button {
  width: 120px;
  margin: 5px auto;
} 