.main-page-header {
  background-color: var(--panel-color);
  padding-left: 10px;
  padding-right: 10px;
  position: sticky;
  top: 0;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 4px 0 rgba(0,0,0,0.2);
  user-select: none;
  z-index: 10;
  color: var(--subtext-color);
  position: relative; /* Ensure the header is a positioning context */
}

.main-page-header-grid {
  width: 100%;
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  align-items: center;
}

.main-page-header-menu {
  display: none;
  font-size: 1.6em;
  justify-content: center;
  align-items: center;
  height: 40px;
  color: #ffffff80;
  cursor: pointer;
}

.main-page-header-logo {
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  padding-left: 5px;
}

.main-page-header-links {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  color: var(--subtext-color);
}

.main-page-header-links a {
  text-decoration: none;
  color: var(--subtext-color) !important;
}

.main-page-header-links a.active {
  text-decoration: none;
  color: var(--text-color) !important;
}

.main-page-header-login {
  background-color: #ffffff40;
  color: white;
  border-radius: 6px;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
  text-wrap: nowrap;
}

.main-page-header-login-button {
  display: none;
  font-size: 1.7em;
  justify-content: center;
  align-items: center;
  height: 40px;
  color: #ffffff80;
  cursor: pointer;
}

.main-page-header-authenticating-button {
  display: flex;
  font-size: 1.5em;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  color: #ffffff80;
  cursor: pointer;
}

.main-page-header-portrait {
  cursor: pointer;
  height: 40px;
  width: 40px;
}

.profile-trigger {
  position: absolute;
  right: 10px; /* Adjust this value as needed */
  top: 50%;
  transform: translateY(-50%);
}

/* Change this media query to a narrower width */
@media (max-width: 800px) { /* Adjust this value as needed */
  .main-page-header-grid {
    grid-template-columns: 40px 1fr 40px;
  }
  .main-page-header-menu {
    display: flex;
  }
  .main-page-header-links {
    display: none;
  }
  .main-page-header-logo {
    text-align: center;
    padding-right: 0px;
    padding-left: 0px;
  }
  .main-page-header-login {
    display: none;
  }
  .main-page-header-login-button {
    display: flex;
  }
  .profile-trigger {
    right: 5px;
  }
}

.dialog-panel {
  max-height: 80vh;
  display: flex;
  flex-direction: column;
}

.dialog-panel > .flex-column {
  overflow-y: auto;
  padding-right: 5px; /* Add a little padding for the scrollbar */
}

.dialog-panel > .flex-column::-webkit-scrollbar {
  width: 6px;
}

.dialog-panel > .flex-column::-webkit-scrollbar-thumb {
  background-color: var(--scroll-thumb-color, #888);
  border-radius: 3px;
}

.dialog-panel > .flex-column::-webkit-scrollbar-track {
  background-color: var(--scroll-track-color, #f1f1f1);
}

@media (max-height: 600px) {
  .dialog-panel {
    max-height: 70vh;
  }
}








