.streamer-page-player {
  color: white;
  background-image: linear-gradient(#EF9A49, #F1B942);
  height: 50px;
  width: 250px;
  font-size: 1.6em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  box-shadow: 0 6px 4px 0 black;
  padding-left: 8px;
  padding-right: 8px;
  text-shadow: 1px 1px 1px black;
}

.streamer-page-clock {
  color: white;
  background-image: linear-gradient(#EF9A49, #F1B942);
  /* height: 50px; */
  width: 240px;
  font-size: 1.6em;
  display: flex;
  flex-direction: column;
  row-gap: 0px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 6px 4px 0 black;
  text-shadow: 1px 1px 1px black;
  padding: 5px;
  cursor: pointer;
  user-select: none; 
}