.races-page-track-time {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

@media (max-width: 500px) {
  .races-page-track-time {
    display: none;
  }
}