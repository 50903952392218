.game-controls {
  position: relative;
  width: 100%;
  padding: 10px;
}

.game-controls-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 5px;
  width: 100%;
}

.game-controls-grid button {
  padding: 5px 0;
}

.network-indicator {
  position: absolute;
  bottom: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.chat-toggle {
  position: absolute;
  bottom: 5px;
  right: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
} 