.car-cosmetics-panel {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  background-color: var(--background-color);
  padding: 10px;
  border-radius: 5px;
}

.cosmetic-item {
  width: calc(20% - 4px);
  aspect-ratio: 1;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.cosmetic-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
} 