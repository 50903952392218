.driver-panel {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
}

.driver-image {
  width: 100%;
  max-width: 150px;
  border-radius: 5px;
  cursor: pointer;
}

.driver-name {
  font-size: 1.1em;
}

.driver-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  color: gray;
}

.nav-button {
  cursor: pointer;
}

.driver-index {
  font-size: 0.9em;
}

.celebration-button {
  width: 100%;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.celebration-label {
  font-size: 0.8em;
  color: gray;
} 