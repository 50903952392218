.how-to-play-container {
  max-width: 1200px;
  margin: 0 auto;
}

.tab {
  transition: background-color 0.3s ease;
}

.tab:hover {
  background-color: #3856B0 !important;
}

.tab.active {
  border-bottom: 3px solid #FFA500;
}