/* Main container */
.drift-page {
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
}

.drift-page h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #fff;
}

/* Selection Grid Layout */
.drift-selection-grid {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

/* Main Selection Panel */
.drift-selection-panel {
  background-color: rgba(26, 39, 86, 0.8);
  padding: 25px;
  border-radius: 15px;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); */
}

/* Selection Area */
.drift-selections {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

/* Stats Area - Combined all stats */
.drift-stats-area {
  background: rgba(0, 0, 0, 0.3);
  padding: 25px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.drift-stats-area h2 {
  color: #fff;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(255, 215, 0, 0.3);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.drift-stats-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

/* Car Column */
.drift-car-column {
  min-width: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Crew Column */
.drift-crew-column {
  min-width: 0;
}

/* Panels */
.drift-car-panel, .drift-crew-panel {
  background-color: rgba(26, 39, 86, 0.8);
  padding: 25px;
  border-radius: 15px;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); */
}

/* Crew Layout */
.drift-crew-section {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 25px;
}

/* Character Card */
.drift-character-card {
  background: rgba(0, 0, 0, 0.3);
  padding: 15px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 420px;
  width: 100%;
  overflow: visible;
  max-width: 100%;
  margin-bottom: 10px;
}

/* Card Title */
.drift-character-card h3 {
  margin: 0 0 15px 0;
  width: 100%;
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
  font-size: 1.1em;
  line-height: 1.2;
  min-height: 2.4em;
}

/* Image Container */
.drift-car-image, .drift-driver-image {
  width: 180px;
  height: 180px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 12px;
  margin: 5px auto 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Common Image Styles */
.drift-car-image img, .drift-driver-image img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
  display: block;
}

/* Remove all car-specific overrides */
.drift-car-panel {
  display: none;  /* Remove since we're using character card */
}


/* Navigation */
.drift-navigation-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 10px;
  padding: 5px 0;
}

/* Stats Layout */
.drift-stat-bar {
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  margin-bottom: 12px;
  background: rgba(0, 0, 0, 0.2);
  padding: 8px 12px;
  border-radius: 6px;
}

.drift-stat-label {
  color: #fff;
  font-weight: bold;
  font-size: 1.2em;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.drift-stat-pips {
  display: flex;
  gap: 4px;
}

.drift-stat-pip {
  width: 30px;
  height: 12px;
  margin-right: 4px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.drift-stat-pip.filled {
  background-color: #4169E1;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 4px rgba(255, 255, 255, 0.3);
}

.drift-stat-pip.boosted {
  background-color: #228B22;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 4px rgba(255, 255, 255, 0.3);
}

/* Right column stats (driver + passenger combined) */
.drift-stats-grid > div:last-child .drift-stat-pip.filled {
  background-color: #4169E1;
}

.drift-stats-grid > div:last-child .drift-stat-pip.boosted {
  background-color: #228B22;
}

/* Stats Container */
.drift-stats-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  row-gap: 8px;
  padding: 20px;
  margin-top: 20px;
}

/* Start Race Button */
.drift-start-race-button {
  display: block;
  margin: 20px auto 0;
  padding: 12px 24px;
  background-color: #ffd700;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  min-width: 200px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  font-size: 1.1em;
}

.drift-start-race-button:hover {
  background-color: #ffed4a;
}

/* Mobile Layout */
@media (max-width: 1400px) {
  .drift-crew-panel {
    min-width: 600px;
  }
}

@media (max-width: 1200px) {
  .drift-crew-section {
    grid-template-columns: repeat(2, 1fr);
  }
  .drift-crew-panel {
    min-width: 0;
  }
}

@media (max-width: 900px) {
  /* Update selection grid first */
  .drift-selection-grid {
    grid-template-columns: 1fr;
  }
  
  /* Then handle crew section */
  .drift-crew-section {
    grid-template-columns: repeat(2, 1fr); /* Keep 2 columns at this size */
  }
}

@media (max-width: 600px) {
  /* Single column for crew at smallest size */
  .drift-crew-section {
    grid-template-columns: 1fr;
  }
}

/* Special case for car stats */
.drift-car-stats .drift-stat-pips {
  grid-template-columns: repeat(5, 1fr);
}

/* Ability */
.drift-ability {
  width: 90%;
  background: rgba(0, 0, 0, 0.4);
  padding: 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  border: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.drift-ability img {
  width: 48px;
  height: 48px;
  margin-bottom: 8px;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5));
  object-fit: contain;
}

.drift-ability span {
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.secondary-button {
  padding: 8px 20px;
  min-width: 110px;
  border-radius: 6px;
}

/* Add section titles */
.drift-section-title {
  font-size: 1.4em;
  margin: 0 0 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(255, 215, 0, 0.3);
}

/* Add title styles */
.drift-panel-title {
  font-size: 1.6em;
  margin: 0 0 20px;
  color: #fff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

/* Car Panel */
.drift-car-panel {
  background-color: rgba(26, 39, 86, 0.8);
  padding: 25px;
  border-radius: 15px;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); */
  margin: 12px;
  width: calc(100% - 24px);
  min-height: 420px;
  display: flex;
  flex-direction: column;
}

/* Car Image Container */
.drift-car-image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 15px;
  margin: 15px 0;
}

.drift-car-image img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
}

/* Car Stats */
.drift-car-stats {
  margin-top: auto;
  padding: 15px 0;
}

/* Stats Layout - make consistent across car and combined stats */
.drift-stat-bar {
  display: grid;
  grid-template-columns: 90px 1fr;
  align-items: center;
  gap: 15px;
  margin-bottom: 8px;
}

/* Remove separate car stats section */
.drift-car-stats-section {
  display: none;  /* Hide this since we moved stats */
}

/* Mobile Responsiveness */
@media (max-width: 1200px) {
  .drift-selections {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
    gap: 15px;
  }
  
  .drift-stats-grid {
    grid-template-columns: 1fr; /* Single column for stats */
    gap: 20px;
  }

  .drift-character-card {
    min-height: 300px; /* Reduce height for tablets */
  }

  .drift-car-image, .drift-driver-image {
    width: 150px; /* Smaller images */
    height: 150px;
  }
}

@media (max-width: 768px) {
  .drift-page {
    padding: 10px; /* Less padding on mobile */
  }

  .drift-selections {
    grid-template-columns: 1fr; /* Single column for phones */
    gap: 15px;
  }

  .drift-selection-panel {
    padding: 15px; /* Less padding */
  }

  .drift-character-card {
    min-height: auto; /* Let it grow with content */
    padding: 15px;
  }

  .drift-car-image, .drift-driver-image {
    width: 120px; /* Even smaller images */
    height: 120px;
    padding: 10px;
  }

  .drift-ability {
    padding: 12px;
    margin: 10px 0;
  }

  .drift-ability img {
    width: 36px;
    height: 36px;
  }

  .drift-stats-area {
    padding: 15px;
  }

  .drift-stat-bar {
    grid-template-columns: 80px 1fr; /* Smaller label column */
    padding: 6px 8px;
    margin-bottom: 8px;
  }

  .drift-stat-label {
    font-size: 1em; /* Smaller font on mobile */
  }

  .drift-stat-pip {
    width: 20px; /* Smaller pips */
    height: 8px;
    margin-right: 2px;
  }

  .drift-navigation-buttons {
    gap: 8px;
  }

  .drift-navigation-buttons button {
    padding: 8px 16px;
    font-size: 0.9em;
  }

  .drift-start-race-button {
    margin-top: 15px;
    padding: 10px 20px;
    font-size: 1em;
  }
}

/* Add iPhone SE / small phone support */
@media (max-width: 380px) {
  .drift-page {
    padding: 5px;
  }

  .drift-selection-panel {
    padding: 10px;
  }

  .drift-car-image, .drift-driver-image {
    width: 100px;
    height: 100px;
  }

  .drift-stat-bar {
    grid-template-columns: 70px 1fr;
  }

  .drift-stat-label {
    font-size: 0.9em;
  }

  .drift-stat-pip {
    width: 15px;
    height: 6px;
  }
}