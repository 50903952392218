.car-panel {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background-color: var(--background-color);
  border-radius: 5px;
}

.car-image {
  width: 100%;
  max-width: 150px;
  border-radius: 5px;
  cursor: pointer;
}

.car-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: gray;
}

.nav-button {
  cursor: pointer;
}

.car-name {
  text-align: center;
}

.car-stats {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.stat-bar {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.stat-label {
  color: gray;
  font-size: 0.8em;
}

.stat-pips {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 5px;
}

.stat-pip {
  height: 10px;
  border-radius: 5px;
} 