.lobby-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: transparent; /* or your desired background color */
    color: #fff; /* Adjust text color for better visibility on dark background */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .lobby-page h1 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .lobby-name-container {
    background-color: transparent; /* Remove white background */
    padding: 10px;
    margin-bottom: 20px;
  }
  
  .lobby-name-container h1 {
    color: #fff; /* Adjust color to ensure visibility */
    text-align: center;
    margin: 0;
  }
  
  .lobby-page-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 1200px;
    width: 100%;
    margin: 50px auto 0;
    padding: 20px;
    background-color: transparent;
    flex: 1;
    position: relative;
  }
  
  .lobby-page-buttons {
    flex: 1;
    margin-right: 20px;
    width: 300px;
    background-color: transparent;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .lobby-page-buttons-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
  }
  
  .lobby-page-buttons-grid button {
    padding: 10px;
    font-size: 14px;
    background-color: #f0ad4e; /* or your preferred button color */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: #333; /* Adjust button text color */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .lobby-page-buttons button:hover {
    background-color: #ff8c00;
  }
  
  .lobby-page-leaderboard {
    flex: 0 0 300px;
    background-color: rgba(255, 255, 255, 0.1); /* semi-transparent */
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .lobby-page-leaderboard h2 {
    color: #fff;
    margin-top: 0;
    margin-bottom: 15px;
  }
  
  .lobby-page-player {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
  }
  
  .lobby-page-player:last-child {
    border-bottom: none;
  }
  
  .lobby-page-network {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  
  .leaderboard-container {
    background-color: #1f2937;
    border-radius: 0.5rem;
    padding: 1.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .leaderboard-header {
    margin-bottom: 1.5rem;
  }
  
  .leaderboard-header h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #f3f4f6;
  }
  
  .leaderboard-header p {
    font-size: 0.875rem;
    color: #9ca3af;
  }
  
  .leaderboard-tabs {
    display: flex;
    border-bottom: 1px solid #374151;
    margin-bottom: 1rem;
  }
  
  .leaderboard-tabs button {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #9ca3af;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .leaderboard-tabs button:hover {
    color: #f3f4f6;
  }
  
  .leaderboard-tabs button.active {
    color: #3b82f6;
    border-bottom-color: #3b82f6;
  }
  
  .leaderboard-table-container {
    overflow-x: auto;
  }
  
  .leaderboard-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }
  
  .leaderboard-table th,
  .leaderboard-table td {
    padding: 0.75rem 1rem;
    text-align: left;
  }
  
  .leaderboard-table th {
    background-color: #374151;
    font-weight: 600;
    color: #f3f4f6;
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 0.05em;
  }
  
  .leaderboard-table tbody tr {
    background-color: #1f2937;
    transition: background-color 0.2s;
  }
  
  .leaderboard-table tbody tr:hover {
    background-color: #2d3748;
  }
  
  .leaderboard-table td {
    color: #d1d5db;
    border-bottom: 1px solid #374151;
  }
  
  .leaderboard-table tr:last-child td {
    border-bottom: none;
  }
  
  .leaderboard-table tr.rank-1 td:first-child {
    color: #fbbf24;
  }
  
  .leaderboard-table tr.rank-2 td:first-child {
    color: #e5e7eb;
  }
  
  .leaderboard-table tr.rank-3 td:first-child {
    color: #d97706;
  }
  
  .refresh-button {
    margin-top: 1rem;
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: #3b82f6;
    color: #ffffff;
    font-size: 0.875rem;
    font-weight: 500;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .refresh-button:hover {
    background-color: #2563eb;
  }
  
  .refresh-button svg {
    margin-right: 0.5rem;
  }

.league-dropdown-container {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000;
}

select.host-page-button.league-dropdown {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #ffa500;
  color: #ffffff; /* Changed to white */
  border: none;
  border-radius: 5px;
  padding: 8px 30px 8px 12px;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 10px auto;
}

select.host-page-button.league-dropdown:hover {
  background-color: #ffb52e;
}

select.host-page-button.league-dropdown:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.5);
}

select.host-page-button.league-dropdown option {
  background-color: #0a192f;
  color: #ffffff; /* Changed to white */
}

.host-page-buttons {
  position: relative;
  width: 100%;
  margin-top: auto;
  padding: 10px;
  bottom: 0;
}

.host-page-buttons > div:first-child {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto auto auto;
  gap: 5px;
  width: 100%;
}

.host-page-buttons button {
  position: relative;  /* Add this to allow absolute positioning of emoji */
  width: 100%;
  height: 100%;
  min-height: 60px;
}

.host-page-buttons button .corner-emoji {
  position: absolute;
  top: 2px;
  right: 2px;
  font-size: 16px;
  color: #FFFFFF;
  text-shadow: 
    0 0 3px rgba(255, 255, 255, 0.8),  /* White glow */
    0 0 4px rgba(0, 0, 0, 0.8);        /* Black glow */
  opacity: 1;
}

.leaderboard-and-buttons-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}
