.privacy-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    color: white;
    font-family: 'Londrina Solid', cursive;
  }
  
  .privacy-page h1 {
    font-size: 2.5em;
    text-align: center;
    margin-bottom: 20px;
    color: white;
  }
  
  .privacy-page h2 {
    font-size: 1.8em;
    margin-top: 30px;
    margin-bottom: 15px;
    color: F09D47;
  }
  
  .privacy-page p {
    margin-bottom: 15px;
    line-height: 1.6;
  }
  
  .privacy-page ul {
    margin-bottom: 15px;
    padding-left: 20px;
  }
  
  .privacy-page li {
    margin-bottom: 5px;
  }
  
  .last-updated {
    text-align: center;
    font-style: italic;
    margin-bottom: 30px;
  }