.season-page-tab, .season-page-tab-active {
  text-align: center;
  /* border-radius: 5px; */
  padding-top: 5px;
  padding-bottom: 5px;
  color: white;
  cursor: pointer;
  /* background-color: #00000020; */
  font-size: 0.9em;
  border: 1px solid #ffffff20;
}

.season-page-tab-active {
  border: 1px solid orange;
  color: orange;
  background-color: var(--panel-background-color);
}