.leaderboard-container {
  background-color: #0a192f;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  padding: 20px;
  max-width: 100%;
  margin: 20px 0;
  color: #e6f1ff;
}

.leaderboard-header {
  margin-bottom: 20px;
}

.leaderboard-title {
  font-size: 24px;
  font-weight: 600;
  color: #ffd700;
  margin: 0 0 10px 0;
}

.leaderboard-subtitle {
  color: #8892b0;
  font-size: 14px;
}

.leaderboard-tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.tab-button {
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}

.tab-button.active {
  background-color: rgba(255, 255, 255, 0.2);
}

.tab-icon {
  margin-right: 8px;
}

.leaderboard-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
}

.rank-cell {
  width: 10%;
  padding: 12px;
  font-weight: bold;
}

.name-cell {
  width: 60%;
  padding: 12px;
}

.value-cell {
  width: 30%;
  padding: 12px;
  text-align: right;
}

.rank-badge {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #1f4068;
  color: #e6f1ff;
  text-align: center;
  line-height: 24px;
  font-size: 12px;
}

.rank-badge.rank-1 { background-color: #ffd700; color: #0a192f; }
.rank-badge.rank-2 { background-color: #c0c0c0; color: #0a192f; }
.rank-badge.rank-3 { background-color: #cd7f32; color: #0a192f; }

.refresh-button {
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.refresh-icon {
  margin-right: 8px;
}

.leaderboard-footer {
  margin-top: 20px;
  text-align: right;
} 