.drift-main-menu {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.drift-status {
  background-color: #0a192f;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.drift-status h2 {
  color: #ffd700;
  margin: 0 0 20px 0;
  text-align: center;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
}

.drift-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.stat-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.stat-label {
  color: #8892b0;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 6px;
}

.stat-value {
  color: #e6f1ff;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.health-bar-container {
  position: relative;
  height: 24px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.health-bar {
  height: 100%;
  transition: width 0.3s ease, background-color 0.3s ease;
  border-radius: 12px;
}

.health-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
  font-size: 14px;
  white-space: nowrap;
}

/* Add a pulsing animation for low health */
@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.7; }
  100% { opacity: 1; }
}

.health-bar[style*="F44336"] {
  animation: pulse 1s infinite;
}

.drift-team-display {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.drift-team-member {
  background-color: #0a192f;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
}

.drift-team-member h3 {
  color: #ffd700;
  margin: 0 0 10px 0;
}

.drift-team-member img {
  width: 100%;
  max-width: 150px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.drift-actions {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 10px 0 30px 0;
  padding: 20px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.drift-actions button {
  min-width: 180px;
  padding: 15px 25px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.drift-actions .primary-button {
  background: linear-gradient(45deg, #ffd700, #ffa500);
  color: #0a192f;
  border: 2px solid #ffd700;
}

.drift-actions .primary-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(255, 215, 0, 0.4);
  background: linear-gradient(45deg, #ffa500, #ffd700);
}

.drift-actions .primary-button:active {
  transform: translateY(1px);
}

.drift-actions .secondary-button {
  background: linear-gradient(45deg, #3b82f6, #1e40af);
  color: white;
  border: 2px solid #3b82f6;
}

.drift-actions .secondary-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(59, 130, 246, 0.4);
  background: linear-gradient(45deg, #1e40af, #3b82f6);
}

.drift-actions .secondary-button:active {
  transform: translateY(1px);
}

.drift-actions button:last-child {
  background: linear-gradient(45deg, #ef4444, #b91c1c);
  border: 2px solid #ef4444;
}

.drift-actions button:last-child:hover {
  boxshadow: 0 6px 20px rgba(239, 68, 68, 0.4);
  background: linear-gradient(45deg, #b91c1c, #ef4444);
}

.drift-actions button::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  transform: rotate(45deg);
  transition: 0.5s;
  opacity: 0;
}

.drift-actions button:hover::after {
  opacity: 1;
  left: 100%;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.drift-actions .primary-button {
  animation: pulse 2s infinite;
}

.drift-actions .primary-button:hover {
  animation: none;
}

/* Leaderboard specific styles */
.drift-main-menu .leaderboard-container {
  background-color: #0a192f;
  margin: 20px 0;
}

.drift-main-menu .leaderboard-table {
  background-color: #1a2942;
}

.drift-main-menu .leaderboard-table tr {
  background-color: #1f2937;
}

.drift-main-menu .leaderboard-table tr:hover {
  background-color: #2d3748;
}

.drift-main-menu .tab-button {
  background-color: rgba(255, 255, 255, 0.1);
  color: #e6f1ff;
  transition: all 0.2s ease;
}

.drift-main-menu .tab-button.active {
  background-color: #ffd700;
  color: #0a192f;
  font-weight: bold;
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.3);
}

.drift-main-menu .tab-button:hover {
  background-color: rgba(255, 215, 0, 0.2);
}

.drift-main-menu .rank-badge {
  background-color: #2d3748;
}

.drift-main-menu .rank-badge.rank-1 { background-color: #ffd700; }
.drift-main-menu .rank-badge.rank-2 { background-color: #c0c0c0; }
.drift-main-menu .rank-badge.rank-3 { background-color: #cd7f32; }

.drift-main-menu .refresh-button {
  background-color: #3b82f6;
}

.drift-main-menu .refresh-button:hover {
  background-color: #2563eb;
}

/* Add specific styling for repair button */
.drift-actions .repair-button {
  background: linear-gradient(45deg, #10b981, #059669);
  border: 2px solid #10b981;
}

.drift-actions .repair-button:hover {
  box-shadow: 0 6px 20px rgba(16, 185, 129, 0.4);
  background: linear-gradient(45deg, #059669, #10b981);
} 