.customize-drivers-dialog {
  max-width: 800px;
  padding: 20px;
}

.drivers-container {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 20px;
  align-items: center;
  background-color: var(--background-color);
  padding: 15px;
  border-radius: 5px;
}

.swap-button {
  cursor: pointer;
  font-size: 1.5em;
  color: gray;
}

.swap-button:hover {
  color: white;
} 