@import url('https://fonts.googleapis.com/css2?family=Londrina+Solid&display=swap');

/* global style variables */

:root {
  --background-color: #11152A;
  --panel-color: #12223E;
  --text-color: white;
  --subtext-color: gray;
  --scroll-track-color: #00000080;
  --scroll-thumb-color: #213e70;
}

/* standard html elements */

html {
  font-family: 'Londrina Solid', cursive;
  font-size: 125%;
}

body {
  background: var(--background-color);
  color: var(--text-color);
  margin: 0px;
}

a {
  color: var(--text-color);
}

a:visited {
  color: var(--text-color);;
}

button {
  font-size: inherit;
  font-family: inherit;
  color: white;
  border-radius: 15px;
  cursor: pointer;
  padding: 8px 12px;
  background: linear-gradient(197.78deg, #EF8F49 -12.54%, #F1BD42 104.72%);
  min-height: 40px;
  min-width: 60px;
  border: 0px;
}

button:disabled {
  background-color: gray;
  background: #ffffff40;
  color: black;
  cursor: not-allowed;
}

input {
  font-size: inherit;
  font-family: inherit;
  border-radius: 6px;
  border: 1px solid black;
  background-color: white;
  color: black;
  padding: 6px;
}

input:disabled {
  background-color: gray;
}

textarea {
  font-size: inherit;
  font-family: inherit;
  border-radius: 6px;
  border: 1px solid black;
  background-color: white;
  color: black;
  padding: 6px;
}

select {
  font-size: inherit;
  font-family: inherit;
  height: 40px;
  border-radius: 6px;
  border: 1px solid black;
  background-color: white;
  color: black;
  padding-left: 6px;
}

/* flex grid */

.flex-row {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
}

.flex-row.space-between {
  justify-content: space-between;
}

.flex-column {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.flex-column-centered {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
}

.flex-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* page helpers */
.page {
  padding: 20px;
}

.panel {
  background-color: var(--panel-color);
  color: var(--text-color);
  padding: 10px;
  border-radius: 10px;
}

.notification-panel {
  background-color: rgb(253, 243, 101);
  color: black;
  padding: 10px;
  border-radius: 10px;
}

.subtext {
  color: var(--subtext-color);
  font-size: 0.9em;
}

.dialog-cover {
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8); 
  opacity: 1;
  pointer-events: visible;
  cursor: default;
}

.dialog-panel, .dialog-panel-centered {
  display: flex;
  flex-direction: column;
  background-color: var(--panel-color);
  color: var(--text-color);
  margin: auto;
  padding: 15px;
  width: calc(100% - 20px);
  max-width: 600px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  position: absolute;
  top: 10%; 
  left: 50%;
  transform: translateX(-50%);
  user-select: none;
}

.dialog-panel-centered {
  top: 10%; 
  transform: translateX(-50%);
}

.dialog-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  position: relative;
}

.dialog-close-button {
  color: var(--subtext-color);
  font-size: 1.0em;
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
}

@media (max-width: 500px) {
  .page {
    padding: 10px;
  }
  .dialog-panel-centered {
    top: 50%; 
    transform: translate(-50%,-50%);
    max-width: calc(100%-20px);
  }
}

/* Add this after the existing html and body styles */

h1, h2, h3, h4, h5, h6 {
  font-family: 'Londrina Solid', cursive;
  margin-bottom: 0.5em;
}

h1 {
  color: var(--text-color); /* This is white as per your existing variable */
  font-size: 2.5em;
}

h2 {
  color: #F0A346; /* This is the specific color you requested for h2 */
  font-size: 2em;
}

h3 {
  color: var(--text-color);
  font-size: 1.75em;
}

h4 {
  color: var(--text-color);
  font-size: 1.5em;
}

h5 {
  color: var(--text-color);
  font-size: 1.25em;
}

h6 {
  color: var(--text-color);
  font-size: 1em;
}

/* You might want to add a specific style for paragraphs as well */
p {
  margin-bottom: 1em;
  line-height: 1.6;
}